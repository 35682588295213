import { sortedLastIndex } from 'lodash'
import { TimeInZoneState } from '../../constants/timeInZoneEnum'
import {
    getFirstIndexInArea,
    getLastIndexInArea,
    findClosestIndexToCenterBackwards,
    findClosestIndexToCenterForward,
} from '../../outtrack/common'
import { byNumber } from '../../sortingfunctions'
import { getAverageSpeed, getEntryExitInfo } from '../speedZoneAnalyzer/speedZoneFunctions'

export const sortByBestTime = (results: CustomZoneResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byBestTime({ number: a.number, laps: a.data.laps }, { number: b.number, laps: b.data.laps })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

export const sortByFinishTime = (results: CustomZoneResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byFinishTime({ number: a.number, laps: a.data.laps }, { number: b.number, laps: b.data.laps })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

export const sortByStartTime = (results: CustomZoneResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byStartTime({ number: a.number, laps: a.data.laps }, { number: b.number, laps: b.data.laps })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

function byBestTime(lapsA: SortingCustom, lapsB: SortingCustom) {
    if (lapsA.laps.length > 0 && lapsB.laps.length > 0) {
        const timesA: number[] = lapsA.laps.map((lap: Laps) => {
            if (lap.exit.timeUtc > lap.entry.timeUtc) {
                return lap.exit.timeUtc - lap.entry.timeUtc
            }
            return -1
        })
        const timesAFiltered = timesA.filter(time => time >= 0)
        const minTimeA = Math.min(...timesAFiltered)

        const timesB: number[] = lapsB.laps.map((lap: Laps) => lap.exit.timeUtc - lap.entry.timeUtc)
        const timesBFiltered = timesB.filter(time => time >= 0)

        const minTimeB = Math.min(...timesBFiltered)
        if (minTimeA !== null && minTimeB !== null) {
            return minTimeA > minTimeB ? 1 : -1
        } else if (minTimeA !== null && minTimeB === null) {
            return 1
        } else if (minTimeA === null && minTimeB !== null) {
            return -1
        }
    }
    if (lapsA.laps.length > 0 && lapsB.laps.length < 1) {
        return 1
    } else if (lapsA.laps.length < 1 && lapsB.laps.length > 0) {
        return -1
    }
    return byNumber(lapsA.number, lapsB.number)
}
function byFinishTime(lapsA: SortingCustom, lapsB: SortingCustom) {
    if (lapsA.laps.length > 0 && lapsB.laps.length > 0) {
        const a = lapsA.laps[0]
        const b = lapsB.laps[0]
        if (a.exit.timeUtc !== null && b.exit.timeUtc !== null) {
            return a.exit.timeUtc > b.exit.timeUtc ? 1 : -1
        } else if (a.exit.timeUtc !== null && b.exit.timeUtc === null) {
            return -1
        } else if (a.exit.timeUtc === null && b.exit.timeUtc !== null) {
            return 1
        }
        if (lapsA.laps.length > 0 && lapsB.laps.length < 1) {
            return 1
        } else if (lapsA.laps.length < 1 && lapsB.laps.length > 0) {
            return -1
        }
    }
    return byNumber(lapsA.number, lapsB.number)
}

function byStartTime(lapsA: SortingCustom, lapsB: SortingCustom) {
    if (lapsA.laps.length > 0 && lapsB.laps.length > 0) {
        const a = lapsA.laps[0]
        const b = lapsB.laps[0]
        if (a.entry.timeUtc !== null && b.entry.timeUtc !== null) {
            return a.entry.timeUtc > b.entry.timeUtc ? 1 : -1
        } else if (a.entry.timeUtc !== null && b.entry.timeUtc === null) {
            return 1
        } else if (a.entry.timeUtc === null && b.entry.timeUtc !== null) {
            return -1
        }
    }
    if (lapsA.laps.length > 0 && lapsB.laps.length < 1) {
        return 1
    } else if (lapsA.laps.length < 1 && lapsB.laps.length > 0) {
        return -1
    }
    return byNumber(lapsA.number, lapsB.number)
}

export function getClosestEntryIndex(entryArea: Area, track: number[][], fromIndex: number) {
    return getClosestIndex(entryArea, track, fromIndex, getLastIndexInArea, findClosestIndexToCenterBackwards)
}

export function getClosestExitIndex(exitArea: Area, track: number[][], fromIndex: number) {
    return getClosestIndex(exitArea, track, fromIndex, getFirstIndexInArea, findClosestIndexToCenterForward)
}

function getClosestIndex(
    area: Area,
    track: number[][],
    fromIndex: number,
    firstIndexFn: (
        longitude: number,
        latitude: number,
        radius: number,
        track: number[][],
        fromIndex: number,
        bearing: number | null
    ) => number | null,
    closestIndexFn: any
) {
    const firstIndex = firstIndexFn(area.longitude, area.latitude, area.radius, track, fromIndex, area.bearing)
    let closestIndex = null
    if (firstIndex !== null) {
        closestIndex = closestIndexFn(area, track, firstIndex)
    }

    return closestIndex
}

export function getLaps(
    participantTrack: number[][],
    entryArea: Area,
    exitArea: Area,
    maxSpeed: number,
    consecSecs: number,
    minSpeed: number,
    tolerance: number,
    minTimeInZone: number
) {
    let laps: Laps[] = []
    const maxSpeedWithtolerance = maxSpeed + tolerance
    let currentIndex = 0
    while (true) {
        let exitIndex
        let entryIndex = getClosestExitIndex(entryArea, participantTrack, currentIndex)
        if (entryArea.longitude === exitArea.longitude && entryArea.latitude === exitArea.latitude) {
            exitIndex = getLastIndexInArea(
                exitArea.longitude,
                exitArea.latitude,
                exitArea.radius,
                participantTrack,
                currentIndex,
                exitArea.bearing
            )
            entryIndex = getFirstIndexInArea(
                entryArea.longitude,
                entryArea.latitude,
                entryArea.radius,
                participantTrack,
                currentIndex,
                entryArea.bearing
            )
        } else {
            exitIndex = getClosestEntryIndex(exitArea, participantTrack, currentIndex)
        }


        if (entryIndex === null && exitIndex === null) {
            return laps
        }

        if (entryIndex !== null && exitIndex !== null) {
            if (entryIndex > exitIndex) {
                entryIndex = null
            } else {
                let indexAfterEntryArea =
                    Number(
                        getLastIndexInArea(
                            entryArea.longitude,
                            entryArea.latitude,
                            entryArea.radius,
                            participantTrack,
                            entryIndex,
                            entryArea.bearing
                        )
                    ) + 1
                let nextEntryIndex = getClosestEntryIndex(entryArea, participantTrack, indexAfterEntryArea)
                if (nextEntryIndex !== null && nextEntryIndex < exitIndex) {
                    exitIndex = null
                }
            }
        }
        const slice = [entryIndex, exitIndex]
        const speedInfo: SpeedInfo = getEntryExitInfo(
            slice,
            participantTrack,
            consecSecs,
            maxSpeedWithtolerance,
            minSpeed,
            tolerance
        )

        const cardSpeedPoint =
            speedInfo.fastestRange !== null
                ? speedInfo.fastestRange.fastestPointInfo.point
                : speedInfo.singleFastestInfo !== null
                  ? speedInfo.singleFastestInfo.point
                  : null
        //const cardExcess = cardSpeedPoint !== null ? Math.max(0, cardSpeedPoint[3] - maxSpeed) : null;
        let minTimeInZoneState = TimeInZoneState.INTIME
        if (entryIndex !== null && exitIndex !== null) {
            minTimeInZoneState =
                participantTrack[exitIndex][2] - participantTrack[entryIndex][2] > minTimeInZone
                    ? TimeInZoneState.INTIME
                    : TimeInZoneState.UNDER
        }
        const cardSlowSpeedPoint = minSpeed
            ? speedInfo.singleSlowestInfo !== null
                ? speedInfo.singleSlowestInfo.point
                : null
            : null

        laps.push({
            maxSpeed: maxSpeed,
            minSpeed: minSpeed,
            entry: {
                index: entryIndex,

                timeUtc: entryIndex === null ? -1 : participantTrack[entryIndex][2],
            },
            exit: {
                index: exitIndex,
                timeUtc: exitIndex === null ? -1 : participantTrack[exitIndex][2],
            },

            speedInfo: speedInfo,
            cardSpeedPoint,
            minTimeInZone: minTimeInZoneState,
            //cardExcess,
            participantAverageSpeed:
                entryIndex === null ||
                exitIndex === null ||
                (maxSpeed === 0 && minSpeed === 0) ||
                (isNaN(maxSpeed) && isNaN(minSpeed))
                    ? null
                    : getAverageSpeed(entryIndex, exitIndex, participantTrack),
            cardSlowSpeedPoint,
            tolerance,
        })
        const lastLapAreaIndex = Math.max(entryIndex, exitIndex)
        const lastLapArea = lastLapAreaIndex === entryIndex ? entryArea : exitArea
        currentIndex =
        Number(
            getLastIndexInArea(
                lastLapArea.longitude,
                lastLapArea.latitude,
                lastLapArea.radius,
                participantTrack,
                lastLapAreaIndex,
                null
            )
        ) + 1
    }
}
