import { all } from 'ol/loadingstrategy'

import * as turf from '@turf/turf'
import {
    calculatePenalization,
    deleteConsecutives,
    getFirstIndexInArea,
    getFirstIndexOutOfArea,
    getIndexesInArea,
    getLastIndexInArea,
    getLastIndexOutOfArea,
} from '../../outtrack/common'
import { byNumber } from '../../sortingfunctions'
import { getClosestEntryIndex, getClosestExitIndex } from '../customZoneAnalyzer/customZoneFunctions'
import { haversineDistance } from '../../utils'
import {
    getIndexesByZones,
    indexOfMaxSpeed,
    infringementsByImpulseInSeconds,
} from '../genericSpeedAnalyzer/genericSpeedFunctions'
import { FireFilled } from '@ant-design/icons'

export const sortByFinishTime = (results: SpeedZoneResult[], ascending: boolean = true) => {
    const sortedResults = results.sort((a, b) =>
        byFinishTime({ number: a.number, zones: a.data.zones }, { number: b.number, zones: b.data.zones })
    )
    return ascending ? sortedResults : sortedResults.reverse()
}

export const byFinishTime = (zonesA: SortingSpeed, zonesB: SortingSpeed) => {
    if (
        zonesA.zones.length > 0 &&
        zonesB.zones.length > 0 &&
        zonesA.zones[zonesA.zones.length - 1][0].analysisInfo.exitInfo &&
        zonesB.zones[zonesB.zones.length - 1][0].analysisInfo.exitInfo
    ) {
        const a = zonesA.zones[zonesA.zones.length - 1][0].analysisInfo.exitInfo.point[2]
        const b = zonesB.zones[zonesB.zones.length - 1][0].analysisInfo.exitInfo.point[2]

        if (a !== null && b !== null) {
            return a > b ? 1 : -1
        } else if (a !== null && b === null) {
            return -1
        } else if (a === null && b !== null) {
            return 1
        }
    }

    if (zonesA.zones.length > 0 && zonesB.zones.length < 1) {
        return 1
    } else if (zonesA.zones.length < 1 && zonesB.zones.length > 0) {
        return -1
    }
    return byNumber(zonesA.number, zonesB.number)
}

export function getAverageSpeed(fromIndex: number, toIndex: number, track: number[][]) {
    let speedAverage = 0
    for (let i = fromIndex; i <= toIndex; i++) {
        speedAverage += track[i][3]
    }

    speedAverage /= toIndex - fromIndex + 1
    return Math.floor(speedAverage)
}

function findexClosestIndexToACertainDistance(
    areaPoint: SpeedZone,
    index: number,
    participantTrack: number[][],
    toleranceRadiusNumber: number
) {
    const d = haversineDistance(participantTrack[index], [areaPoint.longitude, areaPoint.latitude])
    if (toleranceRadiusNumber > d) {
        let index2 = index + 1
        const maxIndex = participantTrack.length
        while (index2 < maxIndex) {
            const d2 = haversineDistance(participantTrack[index2], [areaPoint.longitude, areaPoint.latitude])
            if (toleranceRadiusNumber < d2) {
                return index2
            }
            index2++
        }
    }

    return index
}

function getSpeedZoneIndexes(
    speedZone: InitEndZone,
    participantTrack: number[][],
    threshold: number,
    toleranceRadius: boolean,
    toleranceRadiusNumber: number,
    consecSecs: number,
    maxSpeed: number,
    penaltyTolerance: number,
    criteria: CriteriaAPI,
    speedLimit: number
) {
    let currentIndex = 0
    let speeds: any[] = []

    while (true) {
        let firstIndex: number | null = toleranceRadius
            ? getLastIndexInArea(
                  speedZone.init.longitude,
                  speedZone.init.latitude,
                  90,
                  participantTrack,
                  currentIndex,
                  null
              )
            : toleranceRadiusNumber
              ? findexClosestIndexToACertainDistance(
                    speedZone.init,
                    getClosestExitIndex(speedZone.init, participantTrack, currentIndex),
                    participantTrack,
                    toleranceRadiusNumber
                )
              : getClosestExitIndex(speedZone.init, participantTrack, currentIndex)
        let lastIndex: number | null = toleranceRadius
            ? getFirstIndexInArea(
                  speedZone.end.longitude,
                  speedZone.end.latitude,
                  90,
                  participantTrack,
                  currentIndex,
                  null
              )
            : toleranceRadiusNumber
              ? findexClosestIndexToACertainDistance(
                    speedZone.end,
                    getClosestEntryIndex(speedZone.end, participantTrack, currentIndex),
                    participantTrack,
                    toleranceRadiusNumber
                )
              : getClosestEntryIndex(speedZone.end, participantTrack, currentIndex)
        if (firstIndex === null && lastIndex === null) {
            if (speeds.length === 0) {
                const entryExitInfo = getEntryExitInfo(
                    [firstIndex, lastIndex],
                    participantTrack,
                    consecSecs,
                    maxSpeed,
                    -1,
                    penaltyTolerance,
                    criteria,
                    speedLimit
                )
                speeds.push(entryExitInfo)
            }
            return speeds
        }

        // if (firstIndex !== null && lastIndex !== null) {
        //     if (firstIndex > lastIndex) {
        //         firstIndex = null
        //     }
        // }

        const slice = [firstIndex, lastIndex]
        const entryExitInfo = getEntryExitInfo(
            slice,
            participantTrack,
            consecSecs,
            maxSpeed,
            -1,
            penaltyTolerance,
            criteria,
            speedLimit
        )
        speeds.push(entryExitInfo)
        const lastLapAreaIndex = Math.max(firstIndex!, lastIndex!)
        const lastLapArea = lastLapAreaIndex === firstIndex ? speedZone.init : speedZone.end
        currentIndex =
            Number(
                getLastIndexInArea(
                    lastLapArea.longitude,
                    lastLapArea.latitude,
                    90,
                    participantTrack,
                    lastLapAreaIndex,
                    null
                )
            ) + 1
    }
}

export function chooseMostFavorableEntryExitInfo(
    speedZone: InitEndZone,
    participantTrackA: number[][],
    participantTrackB: number[][],
    threshold: number,
    consecSecs: number,
    maxSpeed: number,
    speedLimit: number,
    criteria: CriteriaAPI,
    penaltyTolerance: number,
    toleranceRadius: boolean,
    toleranceRadiusNumber: number
) {
    let trackSliceA: SpeedInfo[] = getSpeedZoneIndexes(
        speedZone,
        participantTrackA,
        threshold,
        toleranceRadius,
        toleranceRadiusNumber,
        consecSecs,
        maxSpeed,
        penaltyTolerance,
        criteria,
        speedLimit
    )
    const trackAInfo: TrackSpeed = {
        participantTrackType: 'primary',
        speedInfo: trackSliceA,
    }

    let trackSliceB = getSpeedZoneIndexes(
        speedZone,
        participantTrackB,
        threshold,
        toleranceRadius,
        toleranceRadiusNumber,
        consecSecs,
        maxSpeed,
        penaltyTolerance,
        criteria,
        speedLimit
    )
    const trackBInfo: TrackSpeed = {
        participantTrackType: 'secondary',
        speedInfo: trackSliceB,
    }
    const infringementsA = trackAInfo.speedInfo.filter(e => e.infringements.length > 0)
    const infringementsB = trackBInfo.speedInfo.filter(e => e.infringements.length > 0)
    if (infringementsA.length === infringementsB.length && infringementsA.length > 0 && infringementsB.length > 0)
        return infringementsA[0].infringements.length <= infringementsB[0].infringements.length
            ? trackAInfo
            : trackBInfo
    return infringementsA.length >= infringementsB.length ? trackAInfo : trackBInfo

    // if (!trackAInfo.oneIndex.speedInfo.reached) {
    //     return trackAInfo
    // }

    // if (
    //     (trackAInfo.oneIndex.speedInfo.fastestRange !== null) !==
    //     (trackBInfo.oneIndex.speedInfo.fastestRange !== null)
    // ) {
    //     return trackAInfo.oneIndex.speedInfo.fastestRange === null ? trackAInfo : trackBInfo
    // }

    // if (trackAInfo.oneIndex.speedInfo.fastestRange !== null && trackBInfo.oneIndex.speedInfo.fastestRange !== null) {
    //     const speedA = trackAInfo.oneIndex.speedInfo.fastestRange.fastestPointInfo.point[3]
    //     const speedB = trackBInfo.oneIndex.speedInfo.fastestRange.fastestPointInfo.point[3]

    //     return speedA < speedB ? trackAInfo : trackBInfo
    // }

    // if (
    //     (trackAInfo.oneIndex.speedInfo.singleFastestInfo !== null) !==
    //     (trackBInfo.oneIndex.speedInfo.singleFastestInfo !== null)
    // ) {
    //     return trackAInfo.oneIndex.speedInfo.singleFastestInfo === null ? trackBInfo : trackAInfo
    // }

    // if (!trackAInfo.oneIndex.speedInfo.singleFastestInfo && trackBInfo.oneIndex.speedInfo.singleFastestInfo) {
    //     return trackBInfo
    // }

    // const speedA = trackAInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     ? trackAInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     : 0
    // const speedB = trackBInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     ? trackBInfo.oneIndex.speedInfo.singleFastestInfo?.point[3]
    //     : 0
    // return speedA < speedB ? trackAInfo : trackBInfo
}

export function getEntryExitInfo(
    trackSlice: (number | null)[],
    participantTrack: number[][],
    consecSecs: number,
    maxSpeed: number,
    minSpeed: number,
    tolerance: number,
    criteria: CriteriaAPI | null = null,
    speedLimit: number = 0
) {
    const [firstIndex, lastIndex] = trackSlice

    const reached = firstIndex !== null && lastIndex !== null
    const overlapping = firstIndex !== null && lastIndex !== null ? firstIndex > lastIndex : false

    const fastestRange =
        !reached || overlapping
            ? null
            : getFastestRange(firstIndex, lastIndex, participantTrack, consecSecs, maxSpeed, minSpeed)

    const entryInfo =
        firstIndex === null
            ? null
            : {
                  index: firstIndex,
                  point: participantTrack[firstIndex],
              }
    const exitInfo =
        lastIndex === null
            ? null
            : {
                  index: lastIndex,
                  point: participantTrack[lastIndex],
              }

    let cont: number = 0
    const infringements: SpeedInfringements[] = []
    if (firstIndex !== null && lastIndex !== null && !overlapping) {
        let firstTimeInfringement: number = 0
        for (let index = firstIndex; index <= lastIndex; index++) {
            const currPoint = participantTrack[index]

            if (currPoint[3] > maxSpeed) {
                if (cont === 0) firstTimeInfringement = index
                cont++
                if (index === lastIndex) {
                    if (cont >= consecSecs) {
                        let maxSpeed = -1
                        let maxSpeedCoordinates: any = []
                        if (criteria) {
                            let criteriaInfringement: CriteriaInfringement = {
                                money: 0,
                                penalty: 0,
                                numExcessMax: 0,
                            }

                            for (let i = firstTimeInfringement; i <= index; i++) {
                                const value = participantTrack[i][3]
                                if (value > maxSpeed) {
                                    maxSpeed = value
                                    maxSpeedCoordinates = [participantTrack[i][0], participantTrack[i][1]]
                                }
                                const penal = calculatePenalization(
                                    criteria,
                                    tolerance,
                                    speedLimit,
                                    participantTrack[i],
                                    criteriaInfringement.numExcessMax
                                )
                                criteriaInfringement.numExcessMax += penal.numExcessMax
                                criteriaInfringement.penalty += penal.penalty
                                criteriaInfringement.money += penal.money
                            }
                            infringements.push({
                                firstInfringementIndex: firstTimeInfringement,
                                firstPointParticipant: participantTrack[firstTimeInfringement],
                                lastInfringementIndex: index,
                                lastPointParticipant: participantTrack[index],
                                maxSpeed: maxSpeed,
                                criteriaInfringement: criteriaInfringement,
                                maxSpeedCoordinates: maxSpeedCoordinates,
                                timeInfringementCount: cont,
                            })
                        }
                    }
                }
            } else {
                if (cont >= consecSecs) {
                    let maxSpeed = -1
                    let maxSpeedCoordinates: any = []
                    let criteriaInfringement: CriteriaInfringement = {
                        money: 0,
                        penalty: 0,
                        numExcessMax: 0,
                    }

                    if (criteria) {
                        for (let i = firstTimeInfringement; i <= index; i++) {
                            const value = participantTrack[i][3]
                            if (value > maxSpeed) {
                                maxSpeed = value
                                maxSpeedCoordinates = [participantTrack[i][0], participantTrack[i][1]]
                            }
                            const penal = calculatePenalization(
                                criteria,
                                tolerance,
                                speedLimit,
                                participantTrack[i],
                                criteriaInfringement.numExcessMax
                            )
                            criteriaInfringement.numExcessMax += penal.numExcessMax
                            criteriaInfringement.penalty += penal.penalty
                            criteriaInfringement.money += penal.money
                        }

                        infringements.push({
                            firstInfringementIndex: firstTimeInfringement,
                            firstPointParticipant: participantTrack[firstTimeInfringement],
                            lastInfringementIndex: index - 1,
                            lastPointParticipant: participantTrack[index - 1],
                            maxSpeed: maxSpeed,
                            criteriaInfringement: criteriaInfringement,
                            maxSpeedCoordinates: maxSpeedCoordinates,
                            timeInfringementCount: cont,
                        })
                    }
                }
                firstTimeInfringement = 0
                cont = 0
            }
        }
    }
    let singleFastestInfo = null
    if (firstIndex !== null && lastIndex !== null && !overlapping) {
        for (let i = firstIndex; i <= lastIndex; i++) {
            const currPoint = participantTrack[i]
            if (singleFastestInfo === null || singleFastestInfo.point[3] < currPoint[3]) {
                let duration = 1
                if (i > 0) {
                    duration = currPoint[2] - participantTrack[i - 1][2]
                }
                singleFastestInfo = {
                    index: i,
                    point: currPoint,
                    duration,
                }
            }
        }
    }

    let singleSlowestInfo = null
    if (firstIndex !== null && lastIndex !== null && !overlapping) {
        for (let i = firstIndex; i <= lastIndex; i++) {
            const currentPoint = participantTrack[i]
            if (singleSlowestInfo === null || currentPoint[3] < singleSlowestInfo.point[3]) {
                let duration = 1
                if (i > 0) {
                    duration = currentPoint[2] - participantTrack[i - 1][2]
                }
                singleSlowestInfo = {
                    index: i,
                    point: currentPoint,
                    duration,
                }
            }
        }
    }

    return {
        reached,
        overlapping,
        entryInfo,
        exitInfo,
        singleFastestInfo,
        fastestRange,
        singleSlowestInfo,
        infringements: infringements,
    }
}
const DURATION_TIMEOUT = 5

function getFastestRange(
    fromIndex: number,
    toIndex: number,
    participantTrack: number[][],
    consecSecs: number,
    maxSpeed: number,
    minSpeed: number
) {
    let currentRange = null
    let fastestRange = null
    let slowestRange = null
    if (isNaN(maxSpeed) || maxSpeed === 0) return fastestRange
    for (let i = fromIndex; i <= toIndex; i++) {
        let duration = i === 0 ? 1 : participantTrack[i][2] - participantTrack[i - 1][2]
        const currentSpeed = participantTrack[i][3]
        if (!isNaN(maxSpeed)) {
            if (currentSpeed > maxSpeed) {
                if (currentRange === null || duration >= DURATION_TIMEOUT) {
                    fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)

                    currentRange = {
                        duration: duration >= DURATION_TIMEOUT ? 1 : duration,
                        fastestPointInfo: {
                            index: i,
                            point: participantTrack[i],
                        },
                    }
                } else {
                    currentRange.duration += duration
                    if (currentRange.fastestPointInfo.point[3] < currentSpeed) {
                        currentRange.fastestPointInfo = {
                            index: i,
                            point: participantTrack[i],
                        }
                    }
                }
            } else if (currentRange !== null) {
                fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)
                currentRange = null
            }
        } else {
            if (currentSpeed < minSpeed) {
                if (currentRange === null || duration >= DURATION_TIMEOUT) {
                    slowestRange = chooseNewSpeedRange(currentRange, slowestRange, consecSecs)

                    currentRange = {
                        duration: duration >= DURATION_TIMEOUT ? 1 : duration,
                        fastestPointInfo: {
                            index: i,
                            point: participantTrack[i],
                        },
                    }
                } else {
                    currentRange.duration += duration
                    if (currentRange.fastestPointInfo.point[3] < currentSpeed) {
                        currentRange.fastestPointInfo = {
                            index: i,
                            point: participantTrack[i],
                        }
                    }
                }
            } else {
                if (currentRange !== null && !isNaN(maxSpeed)) {
                    slowestRange = chooseNewSpeedRange(currentRange, slowestRange, consecSecs)
                    currentRange = null
                }
            }
        }
    }

    fastestRange = chooseNewSpeedRange(currentRange, fastestRange, consecSecs)

    return fastestRange
}

function chooseNewSpeedRange(currentRange: FastestRange | null, fastestRange: FastestRange | null, consecSecs: number) {
    if (currentRange === null) {
        return fastestRange
    }

    if (currentRange.duration < consecSecs) {
        return fastestRange
    }

    if (fastestRange !== null && currentRange.fastestPointInfo.point[3] <= fastestRange.fastestPointInfo.point[3]) {
        return fastestRange
    }

    return currentRange
}

export function waypointsToSpeedZones(waypoints: SpeedZoneAPI[], threshold: number, toleranceRadius: boolean) {
    waypoints = waypoints.filter(e => e.type === 'DZ' || e.type === 'FZ')

    const res: InitEndZone[] = []
    waypoints
        .filter(e => e.type === 'DZ')
        .forEach(dzWaypoint => {
            const fzWaypoint = waypoints.find(e => e.type === 'FZ' && e.number === dzWaypoint.number)
            if (fzWaypoint !== undefined) {
                const init: SpeedZone = {
                    ...dzWaypoint,
                    radius: 90,
                }
                const end: SpeedZone = {
                    ...fzWaypoint,
                    radius: 90,
                }

                res.push({
                    id: `${init.id}-${end.id}`,
                    init: init,
                    end: end,
                })
            }
        })

    return res
}

export function createAnalisysOfSpeedZones(
    speedZones: any[],
    params: ParamsAnalyzerSpeedZone,
    mainParticipantTrack: number[][],
    secondaryParticipantTrack: number[][],
    state: number
) {
    return speedZones.map(speedZone => {
        const maxUnpenalizableSpeed = speedZone.init.speed! + params.penaltyTolerance
        const infos = chooseMostFavorableEntryExitInfo(
            speedZone,
            mainParticipantTrack,
            secondaryParticipantTrack,
            params.threshold,
            params.consecSecs,
            maxUnpenalizableSpeed,
            speedZone.init.speed!,
            params.speedZoneCriteria,
            params.penaltyTolerance,
            params.toleranceRadius,
            params.toleranceRadiusNumber
        )
        let totalMoney = 0
        let totalPenalty = 0
        infos.speedInfo.forEach(e => {
            totalMoney += e.infringements.reduce((a, b) => a + b.criteriaInfringement.money, 0)
            totalPenalty += e.infringements.reduce((a, b) => a + b.criteriaInfringement.penalty, 0)
        })
        const zones = infos.speedInfo.map(info => {
            const maxSpeed = speedZone.init.speed!
            const cardSpeedPoint =
                info.fastestRange !== null
                    ? info.fastestRange.fastestPointInfo.point
                    : info.singleFastestInfo !== null
                      ? info.singleFastestInfo.point
                      : null
            const cardExcess = cardSpeedPoint !== null ? Math.max(0, cardSpeedPoint[3] - maxSpeed) : 0
            const penalization = 1

            return {
                serverInfo: {
                    zoneNumber: speedZone.init.number,
                    zoneName: speedZone.init.name,
                    maxSpeed,
                    areaPoints: {
                        init: speedZone.init,
                        end: speedZone.end,
                    },
                },
                analysisInfo: {
                    id: `${speedZone.init.id}-${speedZone.end.id}`,
                    participantTrackType: infos.participantTrackType,
                    reached: info.reached,
                    overlapping: info.overlapping,
                    entryInfo: info.entryInfo,
                    exitInfo: info.exitInfo,
                    singleFastestInfo: info.singleFastestInfo,
                    fastestRange: info.fastestRange,
                    infringements: info.infringements,
                    link: `https://anubesport.com/tracking/?rally=rally${params.subrallyId}&port=auto&token=${params.token}&map=satellite&type=animation&participants=${params.participantUINumber}&participant_ids=${params.participant.id}&from=${info.entryInfo?.point[2]}&to=${info.exitInfo?.point[2]}`,
                    penalization,
                    cardSpeedPoint,
                    cardExcess,
                    maxUnpenalizableSpeed,
                },
                state: state,
                params: {
                    toleranceRadius: params.toleranceRadius,
                    penaltyTolerance: params.penaltyTolerance,
                    consecSecs: params.consecSecs,
                    threshold: params.threshold,
                    criteria: params.speedZoneCriteria,
                },
                sandbox: params.modeSandbox,
                totalMoney,
                totalPenalty,
            }
        })
        return zones
    })
}
