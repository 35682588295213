import moment from 'moment-timezone'
import { Coordinate } from 'ol/coordinate'
import { toLonLat } from 'ol/proj'
import { putFiltersBrand } from './outtrack/rallyrestapi'

/**
 * Function that formats the coordinates and copies them to the clipboard.
 *
 * @param coords Unformatted coordinates array of Coordinate
 */
export const copyCoordsToClipboard = (coord: Coordinate): void => {
    const [lon, lat] = toLonLat(coord)
    copyText(getCoordsString(lat, lon))
}

/**
 *  Function that copy to the clipboard
 * @param text Text to copy
 */
export const copyText = (text: string): void => {
    let textArea = document.createElement('textArea')
    textArea.style.position = 'fixed'
    textArea.style.left = '-999999px'
    textArea.style.top = '-999999px'
    document.body.appendChild(textArea)

    textArea.textContent = text
    textArea.focus()
    textArea.onselect

    navigator.clipboard.writeText(textArea.textContent)
    textArea.remove()
}

/**
 * Function that converts a latitude and longitude to a string
 * @param lat Latitude
 * @param lon Longitude
 * @returns Formatted Latitude and longitude
 */
export const getCoordsString = (lat: number, lon: number): string => {
    return `${getCoordString(lat)}, ${getCoordString(lon)}`
}

/**
 * Function that converts a coordinate to a string
 * @param coord A coordinate, could be a latitude or longitude
 * @returns Formatted coordinate
 */
export const getCoordString = (coord: number): string => {
    return coord.toFixed(7)
}

/**
 * Function that calculates the lost positions of the gps,
 *  it is considered lost if the difference between points is greater than 3 seconds.
 * @param positions Positions of Partipant to track,
 * @returns Sum of positions lost
 */
export const calculateLostPositions = (positions: number[][][]): number => {
    let calculatedPositions = 0
    if (positions) {
        for (let index = 0; index < positions.length; index++) {
            const element = positions[index]
            for (let j = 1; j < element.length; j++) {
                calculatedPositions = calculatedPositions + (element[j][2] - element[j - 1][2]) / 3
            }
        }
    }
    return Math.floor(calculatedPositions as number)
}

/**
 * Function that transforms a time in seconds into an easier to read format.
 * @param time seconds to convert
 * @returns Converted seconds in a string
 */
export const secondsToPrettyformat = (time: number | undefined): string => {
    if (!time) return ''
    const [h, m, s] = splitTimespan(time)

    let result = ''

    if (h > 0) {
        result += `${h} h `
    }

    if (m > 0) {
        result += `${m} min `
    }

    if (s > 0) {
        result += `${s} seg.`
    }

    return result.trim()
}

/**
 * Function that converts an unixtime time into hh:mm:ss format
 * @param utc Unixtime to convert
 * @param timezone Timezone
 * @returns Time in HH:mm:ss format
 */
export const timeUtcToHHMMSS = (utc: number, timezone: string): string => {
    if (timezone && moment.tz.names().includes(timezone)) {
        const time = moment(utc * 1000)
            .tz(timezone)
            .format('HH:mm:ss')
        return time
    }
    return 'Invalid date'
}

/**
 * Function to format ut time into HH:mm
 * @param ut Time in unix time
 * @param timezone place to which the time is to be formatted
 * @returns Time formatted in HH:mm
 */
export const timeUtcToHHMM = (ut: number, timezone: string) => {
    if (timezone && moment.tz.names().includes(timezone)) {
        const time = moment(ut * 1000)
            .tz(timezone)
            .format('HH:mm')
        return time
    }
    return 'Invalid date'
}

/**
 * Function that convert seconds into a format hh:mm:ss
 * @param timespanSeconds Seconds
 * @returns Formatted Time in HH:mm:ss
 */
export const timespanToHHMMSS = (timespanSeconds: number): string => {
    if (timespanSeconds !== null) {
        const splitted = splitTimespan(timespanSeconds)
        if (splitted.length > 0) {
            return splitted.map(n => n.toString().padStart(2, '0')).join(':')
        }
        return 'Invalid time'
    }
    return 'Invalid time'
}

/**
 * Function looks at the difference between the two angles.
 * @param a Fisrt angle
 * @param b Second Angle
 * @returns The difference between the two angles
 */
export const innerAngle = (a: number, b: number) => {
    const angleDiff = ((b - a + 180 + 360) % 360) - 180
    return Math.abs(angleDiff)
}

/**
 * Function that splits seconds to get the hours ,minutes and seconds
 * @param timespanSeconds Seconds
 * @returns Array that includes hours,minutes and seconds
 */
export const splitTimespan = (timespanSeconds: number): number[] => {
    if (timespanSeconds !== null && !isNaN(Number(timespanSeconds)) && timespanSeconds > -1) {
        const hours = Math.floor(timespanSeconds / 3600)
        const minutes = Math.floor((timespanSeconds % 3600) / 60)
        const seconds = Math.floor(timespanSeconds % 60)
        return [hours, minutes, seconds]
    }
    return []
}

export const haversineDistance = (coords1: number[], coords2: number[]) => {
    const toRad = (angle: number) => (angle * Math.PI) / 180

    const lat1 = coords1[1]
    const lon1 = coords1[0]
    const lat2 = coords2[1]
    const lon2 = coords2[0]

    const R = 6371000 // Radio de la Tierra en metros
    const dLat = toRad(lat2 - lat1)
    const dLon = toRad(lon2 - lon1)

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
}

export const formatUT = (unixTimestamp: number) => {
    // Ensure the timestamp is a number and round to one decimal place
    const timestamp = Math.round(unixTimestamp)
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(timestamp / 3600)
    const minutes = Math.floor((timestamp % 3600) / 60)
    const seconds = timestamp % 60

    // Format time with leading zeros where needed
    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(minutes).padStart(2, '0')
    const formattedSeconds = String(seconds).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export const objectContains = (object1: any, object2: any) => {
    for (let key in object2) {
        if (object2.hasOwnProperty(key) && object1.hasOwnProperty(key)) {
            if (JSON.stringify(object1[key]) !== JSON.stringify(object2[key])) {
                return false
            }
        } else {
            return false
        }
    }
    for (let key in object1) {
        if (object2.hasOwnProperty(key) && object1.hasOwnProperty(key)) {
            if (JSON.stringify(object1[key]) !== JSON.stringify(object2[key])) {
                return false
            }
        } else {
            return false
        }
    }
    return true
}

export const handlerFiltersPutBrand = async (
    rallyId: number,
    sectionId: number,
    token: string,
    detectionType: string,
    specificParams: any,
    brandFilters: any
) => {
    if (brandFilters.length > 0) {
        const jsonBrandFilters = JSON.parse(brandFilters[0].data)
        if (!objectContains(specificParams, jsonBrandFilters)) {
            return { jsonBrandFilters, specificParams }
        }
    } else {
        await putFiltersBrand(rallyId, token, detectionType, sectionId, specificParams)
    }
}

