import React from 'react'
import ParticipantCard from '../participantCard'
import moment from 'moment'
import 'moment-timezone'
import { timespanToHHMMSS, secondsToPrettyformat } from '../../../utils'

import '../summary_card.css'

interface Props {
    result: NeutralizationResult
    updatePointer: (a: number, b: number) => void
    participantTracks: number[][][]
    timezone: string
    onClick: (a: NeutralizationResult) => void
    className: string
}
const NeuZonesCard = ({ className, onClick, participantTracks, result, timezone, updatePointer }: Props) => { 
    const zonesInfo = result.data.results.map(zone => {
        let durationInZone, timeInfo, lackInfo, penalization
        let tagDuractionClassName = 'item waypoint'
        let tagLackClassName = 'item waypoint'
        if (zone.diffTime) {
            durationInZone = timespanToHHMMSS(zone.diffTime)
            lackInfo = secondsToPrettyformat(zone.lack)
            penalization = zone.penalization
            timeInfo = (
                <React.Fragment>
                    <span
                        className="clickable"
                        onClick={() => {
                            const participantTrackIndex = zone.participantTrack === 'primary' ? 0 : 1
                            const point = participantTracks[participantTrackIndex][zone.entryIndex]
                            updatePointer(point[0], point[1])
                        }}
                    >
                        {' ' +
                            moment(zone.entryTimeUtc! * 1000)
                                .tz(timezone)
                                .format('HH:mm:ss') +
                            ' '}
                    </span>
                    <span
                        className="clickable"
                        onClick={() => {
                            const participantTrackIndex = zone.participantTrack === 'primary' ? 0 : 1
                            const point = participantTracks[participantTrackIndex][zone.exitIndex]
                            updatePointer(point[0], point[1])
                        }}
                    >
                        {' ' +
                            moment(zone.exitTimeUtc! * 1000)
                                .tz(timezone)
                                .format('HH:mm:ss') +
                            ' '}
                    </span>
                </React.Fragment>
            )

            if (zone.penalization) {
                tagDuractionClassName += ' error-tag duration-tag'
                tagLackClassName += ' error-tag'
            } else {
                tagDuractionClassName += ' ok-tag duration-tag'
                tagLackClassName += ' ok-tag'
            }
        } else {
            if (zone.entryTimeUtc) {
                timeInfo = (
                    <React.Fragment>
                        <span
                            className="clickable"
                            onClick={() => {
                                const participantTrackIndex = zone.participantTrack === 'primary' ? 0 : 1
                                const point = participantTracks[participantTrackIndex][zone.entryIndex]
                                if (point) {
                                    updatePointer(point[0], point[1])
                                }
                            }}
                        >
                            {' ' +
                                moment(zone.entryTimeUtc * 1000)
                                    .tz(timezone)
                                    .format('HH:mm:ss') +
                                ' '}
                        </span>
                        <span
                       className='neutral-tag text-white'
                    >
                        {' ' +
                            moment((zone.entryTimeUtc + zone.init.seconds) * 1000 )
                                .tz(timezone)
                                .format('HH:mm:ss') +
                            ' '}
                    </span>
                    </React.Fragment>
                )
                lackInfo = ' - '
                durationInZone = <div> Zone not reached </div>
                tagDuractionClassName += ' neutral-tag'
                tagLackClassName += ' neutral-tag'
                penalization = ' - '
            } else {
                if (zone.exitTimeUtc) {
                    timeInfo = (
                        <>
                            <span className="clickable"> -- </span>
                            <span
                                className="clickable"
                                onClick={() => {
                                    const participantTrackIndex = zone.participantTrack === 'primary' ? 0 : 1
                                    const point = participantTracks[participantTrackIndex][zone.exitIndex]
                                    updatePointer(point[0], point[1])
                                }}
                            >
                                {' ' +
                                    moment(zone.exitTimeUtc * 1000)
                                        .tz(timezone)
                                        .format('HH:mm:ss') +
                                    ' '}
                            </span>
                        </>
                    )
                    lackInfo = ' - '
                    durationInZone = <div> WARNING NOT ENTRY TIME </div>
                    tagDuractionClassName += ' warning-tag'
                    tagLackClassName += ' warning-tag'
                    penalization = ' - '
                } else {
                    lackInfo = ' - '
                    durationInZone = <div> Zone not reached </div>
                    tagDuractionClassName += ' neutral-tag'
                    tagLackClassName += ' neutral-tag'
                    penalization = ' - '
                }
            }
        }

        return (
            <div key={zone.id} className="flex-1">
                <b>{zone.init.name}</b>
                <span className="ml-2 w-[30px] rounded bg-slate-300 font-bold">{zone.init.seconds / 60}'</span>
                {timeInfo}

                <div className={tagDuractionClassName}>{durationInZone}</div>
                {lackInfo && <div className={tagLackClassName}>{lackInfo}</div>}

                {/* {zone.penalization !== 0 && <div className="item waypoint neutral-tag">{penalization}</div>} */}
            </div>
        )
    })
    if (!zonesInfo.length) {
        return <></>
    }
    return (
        <ParticipantCard
            rallyId={result.rally}
            number={result.number}
            onClick={() => onClick(result)}
            type="Neutralization Zones"
            className={className}
        >
            <div className="float-right mr-2 flex-1 text-right">{zonesInfo}</div>
        </ParticipantCard>
    )
}

export default NeuZonesCard
