import { useEffect, useState, type Key } from 'react'
import ErrorBoundarySummaryCard from '../../components/ErrorBoundarySummaryCard/error_boundary_summary_card'
import '../../app.css'
import { useSelectedResultEffect } from '../../hooks/useSelectedResult'
import { useResultsEffect } from '../../hooks/useResults'
import { defaultSortings } from '../../constants/sortingConstants'
import HeaderData from '../../components/dataHeader'
import { Footer } from 'antd/es/layout/layout'
import SpeedZonesDownloader from '../../components/ExcelDownloaders/SpeedZonesDownloader'
import { byFinishTime, sortByFinishTime } from './speedZoneFunctions'
import SpeedCard from '../../components/ParticipantCard/SpeedCard/speedCard'
import { Divider } from 'antd'

interface Props {
    results: SpeedZoneResult[]
    setRallyMap: React.Dispatch<React.SetStateAction<RallyMap>>
    setSpeedGraphic: React.Dispatch<React.SetStateAction<SpeedGraphic>>
    timezone: string
    onClick: (lon: number, lat: number) => void
}
const SpeedZoneView = ({ results, setRallyMap, setSpeedGraphic, timezone, onClick }: Props) => {
    const [selectedResult, setSelectedResult] = useState<SpeedZoneResult | null>(null)
    const [infrigments, setInfrigments] = useState<boolean>(true)
    const [completed, setCompleted] = useState<boolean>(false)
    const ZONE_ALL = 'ALL'
    const [filterZoneName, setFilterZoneName] = useState<string>(ZONE_ALL)

    useSelectedResultEffect(selectedResult, setRallyMap, setSpeedGraphic)

    const sortings: SortingObject[] = [
        ...defaultSortings,
        {
            name: 'Finish time (ascending)',
            sorting: sortByFinishTime,
        },
        {
            name: 'Finish time (descending)',
            sorting: (a: SpeedZoneResult[]) => sortByFinishTime(a, false),
        },
    ]
    const [filteredResults, setFilteredResults] = useState<SpeedZoneResult[]>(results)
    const [sorting, setSorting] = useState<(a: Results, b: Results) => number>(() => sortings[0].sorting)

    const changeInfrigmentsCheckbox = (event: any) => {
        setInfrigments(event.target.checked)
    }

    const changeCompletedCheckbox = (event: any) => {
        setCompleted(event.target.checked)
    }
    const filteredComponentsByModes = [
        {
            name: 'Show infrigments mode',
            checked: infrigments,
            function: changeInfrigmentsCheckbox,
        },
        {
            name: 'Show completed zones mode',
            checked: completed,
            function: changeCompletedCheckbox,
        },
    ]
    const zoneNames = [
        ZONE_ALL,
        ...results[0].data.zones.map((zone: SpeedZoneData[]) => {
            if (zone !== undefined) {
                const zones = zone
                    .map(value => {
                        if (value.serverInfo.zoneName !== undefined) {
                            return value.serverInfo.zoneName
                        }
                        return ''
                    })
                    .filter(name => name !== '')
                return zones
            }
            return []
        }),
    ].map(e => {
        if (Array.isArray(e)) {
            return { value: e[0], label: e[0] }
        }
        return { value: e, label: e }
    })

    const zonesFilter = (result: SpeedZoneResult) => {
        const newResultFiltered = result.data.zones.map((zone: SpeedZoneData[]) => {
            if (zone !== undefined) {
                const zoneAux = zone.filter(
                    (zoneEntry: SpeedZoneData) =>
                        [ZONE_ALL, zoneEntry.serverInfo.zoneName].indexOf(filterZoneName) !== -1
                )
                return zoneAux.length > 0 ? zoneAux : []
            }
            return []
        })
        result.data.zones = newResultFiltered
        return result
    }

    const changeZoneNameFilter = (name: string) => {
        setFilterZoneName(name)
    }
    const filteredComponentByZone = {
        name: 'Zone',
        values: zoneNames,
        value: filterZoneName,
        onChange: changeZoneNameFilter,
    }
    const completedFilter = (result: SpeedZoneResult) => {
        const newResultFiltered = result.data.zones
            .map((zone: SpeedZoneData[]) => {
                if (zone !== undefined) {
                    const zoneAux = zone.filter(
                        (zoneEntry: SpeedZoneData) =>
                            zoneEntry.analysisInfo.reached &&
                            !zoneEntry.analysisInfo.overlapping &&
                            zoneEntry.analysisInfo.fastestRange === null
                    )

                    return zoneAux.length >= zone.length ? zoneAux : []
                }
                return []
            })
            .filter((zoneEntry: SpeedZoneData[]) => {
                return zoneEntry.length > 0
            })
        const totalZones = result.data.zones.filter((zoneEntry: SpeedZoneData[]) => zoneEntry.length > 0)
        if (newResultFiltered.length === totalZones.length) {
            result.data.zones = newResultFiltered
        } else {
            result.data.zones = []
        }
        return result
    }

    const infrigmentsFilter = (result: SpeedZoneResult) => {
        result.data.zones = result.data.zones
            .map((zone: SpeedZoneData[]) => {
                if (zone !== undefined) {
                    const zoneAux = zone.filter(
                        (zoneEntry: SpeedZoneData) =>
                            zoneEntry.analysisInfo.reached && zoneEntry.analysisInfo.fastestRange !== null
                    )
                    return zoneAux.length > 0 ? zoneAux : []
                }
                return []
            })
            .filter((zoneEntry: SpeedZoneData[]) => zoneEntry.length > 0)

        return result
    }
    const filterApply = (result: SpeedZoneResult) => {
        const newResult = Object.assign({}, result)
        newResult.data = Object.assign({}, result.data)
        zonesFilter(newResult)
        if (infrigments) {
            infrigmentsFilter(newResult)
        }
        if (completed) {
            completedFilter(newResult)
        }
        return newResult
    }
    const applyFilters = (sortedResults: SpeedZoneResult[]) => {
        let shownResults = sortedResults.map(e => filterApply(e))

        setFilteredResults(shownResults)
    }

    useResultsEffect(results, sorting, [infrigments, completed, filterZoneName], applyFilters)

    return (
        <div className="flex h-full flex-col">
            <div className="m-2 ">
                <HeaderData
                    defaultSorting={sortings[0]}
                    sortings={sortings}
                    onChangeSorting={e => setSorting(() => e)}
                    filteredComponentByZone={filteredComponentByZone}
                    filterComponents={filteredComponentsByModes}
                ></HeaderData>
            </div>
            <div className="h-full flex-1 overflow-y-auto p-2">
                {filteredResults.map((result: SpeedZoneResult, index: Number) => {
                    const selected = selectedResult !== null && selectedResult.id === result.id
                    return (
                        <div key={index as Key}>
                            <ErrorBoundarySummaryCard number={result.number} key={result.id}>
                                <SpeedCard
                                    className={selected ? 'selected' : ''}
                                    result={result}
                                    onClick={(result: SpeedZoneResult) => setSelectedResult(result)}
                                    timezone={timezone}
                                    updatePointer={onClick}
                                    participantTracks={result.participantTracks}
                                />
                            </ErrorBoundarySummaryCard>
                        </div>
                    )
                })}
            </div>
            <div className="mx-2 pt-1">
                <Divider className="m-0 bg-neutral-300" />
            </div>
            <Footer className="m-1 rounded-md bg-neutral-100 p-4 text-center ">
                <SpeedZonesDownloader
                    timezone={timezone}
                    info={filteredResults}
                    disabled={false}
                    text="Save report as excel"
                ></SpeedZonesDownloader>
            </Footer>
        </div>
    )
}

export default SpeedZoneView
