import { FaMapMarkerAlt } from 'react-icons/fa'
import { putWaypointCheck } from '../../../../analyzer/waypointsAnalyzer/waypointsFunctions'
import { useState } from 'react'

interface Props {
    waypoints: Waypoint[]
    openPopup: any
    subrallyId: number
    title: string
    token: string
    mainSerialNumber: number
    participantId: number
}

const WaypointsTable = ({ waypoints, mainSerialNumber, title, openPopup, subrallyId, token, participantId }: Props) => {
    const [waypointsTable, setWaypointsTable] = useState<Waypoint[]>(waypoints)

    const removeWaypoint = (waypointId: number) => {
        const updatedWaypoints = waypointsTable.filter(wp => wp.id !== waypointId)
        setWaypointsTable(updatedWaypoints)
    }
    if (waypointsTable.length < 1) {
        return (
            <div className={`text-right] m-1 flex flex-row justify-center text-nowrap rounded font-semibold`}>
                Empty Table
            </div>
        )
    }
    return (
        <>
            {waypointsTable.map((waypoint, index) => {
                return (
                    <div
                        className={`m-1 flex flex-row justify-start text-nowrap rounded pl-1`}
                        onClick={() => openPopup(waypoint.name)}
                        key={index}
                    >
                        <div className="flex flex-row space-x-1 self-center rounded ">
                            <div className="italic underline">{title}</div>
                            <div className="w-12 overflow-hidden text-ellipsis whitespace-nowrap text-end font-bold hover:cursor-pointer">
                                {waypoint.name}
                            </div>
                            <a
                                className=""
                                target="_blank"
                                rel="noopener noreferrer"
                                href={waypoint.adminLink}
                                title="Waypoint Link"
                            >
                                Waypoint link
                            </a>
                        </div>
                        <div
                            className="ml-2"
                            onClick={event => {
                                event.stopPropagation()
                                putWaypointCheck(waypoint, subrallyId, token, participantId, 1, mainSerialNumber)
                                    .then(value => {
                                        removeWaypoint(waypoint.id)
                                    })
                                    .catch(error => {
                                        console.error('Error:', error)
                                    })
                            }}
                        >
                            <FaMapMarkerAlt className="text-[green] hover:cursor-pointer" />
                        </div>
                        <div
                            className="ml-2"
                            onClick={event => {
                                event.stopPropagation()
                                putWaypointCheck(waypoint, subrallyId, token, participantId, 0, mainSerialNumber)
                                    .then(value => {
                                        removeWaypoint(waypoint.id)
                                    })
                                    .catch(error => {
                                        console.error('Error:', error)
                                    })
                            }}
                        >
                            <FaMapMarkerAlt className="text-[red] hover:cursor-pointer" />
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default WaypointsTable
