import { getWaypointsForTrack } from '../../lib/reports/waypoints/getWaypointsForTrack'
import type WaypointsAnalyzer from './waypointsAnalyzer'

export default class WaypointsHandler {
    analyzer: WaypointsAnalyzer
    section: Section
    excludedWaypointIndexes: any

    constructor(analyzer: WaypointsAnalyzer, section: Section, excludedWaypointIndexes: any) {
        this.analyzer = analyzer
        this.section = section
        this.excludedWaypointIndexes = excludedWaypointIndexes
    }

    getAreaPoints(resultInfo: WaypointsReport) {
        const COLOR_EXCLUDED = '#FF8C00'
        const COLOR_MISSED_VALIDATED = '#FF0000'
        const COLOR_MISSED_NOT_VALIDATED = '#f87171'
        const COLOR_MATCHED = '#00FF00'
        const COLOR_MATCHED_GPS = ' #008d00'
        const COLOR_MATCHED_GPS_AND_BLOCKED = ' #003200'

        const getWaypointColor = (waypoint: Waypoint) => {
            if (waypoint.excluded) {
                return COLOR_EXCLUDED
            }
            if (waypoint.matchedGps && waypoint.blocked) {
                return COLOR_MATCHED_GPS_AND_BLOCKED
            }
            if (waypoint.matchedGps) {
                return COLOR_MATCHED_GPS
            }
            if (waypoint.matched) {
                return COLOR_MATCHED
            } else {
                if (waypoint.blocked) {
                    return COLOR_MISSED_VALIDATED
                } else {
                    return COLOR_MISSED_NOT_VALIDATED
                }
            }
        }

        return resultInfo?.waypoints.map((waypoint, index) => {
            const areaPointsWaypoints: AreaPointsWaypoints = {
                name: waypoint.name,
                coords: waypoint.coordinate,
                radius: waypoint.validation_radio,
                color: getWaypointColor(waypoint),
                matched: waypoint.matched,
                matchedGps: waypoint.matchedGps,
                timeMatched: waypoint.timeMatched,
                firstUnfinished: waypoint.firstUnfinished,
                unfinished: waypoint.unfinished,
                buttons: [
                    {
                        name: 'Disable',
                        onClick: () => this._excludeWaypoint(index),
                    },
                ],
                adminLink: waypoint.adminLink,
            }

            return areaPointsWaypoints
        })
    }

    excludeOutliers(resultInfo: WaypointsReport, excludedWaypoints: any[]) {
        for (let index of excludedWaypoints) {
            resultInfo.waypoints[index].excluded = true
        }
    }

    _excludeWaypoint(index: number) {
        let newExcludedWaypointIndexes = [...this.excludedWaypointIndexes]
        newExcludedWaypointIndexes.push(index)

        const results = this.analyzer.getResults()

        const process = (results: WaypointsResult[]) => {
            for (let result of results) {
                this.excludeOutliers(result.data, newExcludedWaypointIndexes)

                result.areaPoints = this.getAreaPoints(result.data)
            }

            return results
        }

        this.analyzer.onExcludedWaypointIndexes(newExcludedWaypointIndexes)
        this.analyzer.onResults(process(results))
    }

    async getAnalysisSpecificInfo(rallyId: number, section: Section, token: string): Promise<Waypoint[]> {
        const sectionId = section.section

        return await getWaypointsForTrack(rallyId, sectionId, token)
    }
}
