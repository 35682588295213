import { handlerFiltersPutBrand } from '../../utils'
import Analyzer from '../analyzer'
import SpeedZoneHandler from './speedZoneHandler'

export default class SpeedZoneAnalyzer extends Analyzer {
    speedZoneResult: SpeedZoneResult[]
    resultHandler: SpeedZoneHandler

    constructor(rallyId: number, subRallyId : number, section: Section, excluded: any, connections: DevicesConnections, routesReports: any) {
        super(rallyId,subRallyId, section, excluded, connections, routesReports)
        this.resultHandler = new SpeedZoneHandler()
        this.speedZoneResult = []
    }

    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'speed')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any,
        modeSandBox: boolean
    ) => {
        const specificParams = this._getRequestParamsSpecific(data)
        let brands = null
        if (!modeSandBox) {
            brands = await handlerFiltersPutBrand(
                this.subrallyId,
                this.section.section,
                token,
                data.detectionType,
                specificParams,
                brandFilters
            )
        }
        if (brands) return brands
        let analysisSpecificInfo: SpeedZoneAPI[]
        try {
            analysisSpecificInfo = await this.resultHandler.getAnalysisSpecificInfo(
                this.subrallyId,
                this.section,
                token
            )
        } catch (e) {
            console.log('getAnalysisSpecificInfo exception:', e)
            this.onResultError()
            return
        }
        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.subrallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections,
                analysisSpecificInfo
            )
            params.modeSandbox = modeSandBox
            const worker = await this._pool.get()
            if (worker == null) {
                break
            }

            worker.onmessage = async (e: MessageEvent<MessageAlgorithmSpeedZone>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'speedZoneResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: SpeedReport = e.data.result

                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: SpeedZoneResult = {
                            highlightedPoints: resultInfo.highlightedPoints,
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.subrallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: resultInfo.areaPoints,
                            customTracks: [],
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.speedZoneResult = [...this.speedZoneResult, result]
                        this.onResults(this.speedZoneResult)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }
            worker.postMessage(['speedZoneProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.speedZoneResult

    _getRequestParamsSpecific = (data: DataRallyForm) => {
        return {
            threshold: { speed: data.detectionTypeData.threshold.speed },
            toleranceRadius: data.detectionTypeData.toleranceRadius,
            consecSecs: Number(data.detectionTypeData.consecSecs),
            penaltyTolerance: Number(data.detectionTypeData.penaltyTolerance),
            toleranceRadiusNumber: data.detectionTypeData.toleranceRadiusNumber ? Number(data.detectionTypeData.toleranceRadiusNumber) : null,
            speedZoneCriteria: data.detectionTypeData.speedZoneCriteria,
        }
    }
    _getRequestParams = (
        rallyId: number,
        subrallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: DevicesConnections,
        analysisSpecificInfo: SpeedZoneAPI[]
    ): ParamsAnalyzerSpeedZone => {
        const reqData: ParamsAnalyzerSpeedZone = {
            token: token,
            subrallyId: subrallyId,
            rallyId: rallyId,
            section: section,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            speedZoneCriteria: data.detectionTypeData.speedZoneCriteria,
            threshold: data.detectionTypeData.threshold.speed,
            toleranceRadius: data.detectionTypeData.toleranceRadius,
            toleranceRadiusNumber: data.detectionTypeData.toleranceRadiusNumber,
            participant: participant,
            analysisSpecificInfo: analysisSpecificInfo,
            consecSecs: Number(data.detectionTypeData.consecSecs),
            penaltyTolerance: Number(data.detectionTypeData.penaltyTolerance),
        }
        
        return reqData
    }
}
