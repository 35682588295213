import * as XLSX from 'xlsx'
import { timespanToHHMMSS, timeUtcToHHMMSS } from '../../utils'
import type { ExcelData, SpeedExcelRow } from '../../types/excel'

export const speedZoneExcelData = (info: SpeedZoneResult[], timezone: string) => {
    var speedExcel: SpeedExcelRow[] = []

    info.forEach((participantResult: SpeedZoneResult) => {
        const speedZones = participantResult.data.zones
        speedZones.forEach((speedZonesDatas: SpeedZoneData[]) => {
            speedZonesDatas.forEach((speedZoneData, index) => {
                const zoneResult = speedZoneData.analysisInfo
                if (zoneResult.reached && !zoneResult.overlapping) {
                    speedExcel.push({
                        'Number Run': index + 1,
                        Participant: participantResult.number,
                        'Zone name': speedZoneData.serverInfo.zoneName,
                        'Speed Limit': speedZoneData.serverInfo.maxSpeed,
                        'Entry time': timeUtcToHHMMSS(zoneResult.entryInfo.point[2], timezone),
                        'Exit time': timeUtcToHHMMSS(zoneResult.exitInfo.point[2], timezone),
                        'Total time': timespanToHHMMSS(zoneResult.exitInfo.point[2]-zoneResult.entryInfo.point[2]),
                        "Infringement time": timeUtcToHHMMSS(zoneResult.cardSpeedPoint[2],timezone),
                        'Max speed (km/h)': zoneResult.cardSpeedPoint[3],
                        'Infringement (km/h)': zoneResult.cardExcess,
                        'Speeding time (s)':zoneResult.fastestRange ?  zoneResult.fastestRange.duration : 0,
                    })
                } else {
                    speedExcel.push({
                        'Number Run': index + 1,
                        Participant: participantResult.number,
                        'Zone name': speedZoneData.serverInfo.zoneName,
                        'Speed Limit': speedZoneData.serverInfo.maxSpeed,
                    })
                }
            })
        })
    })

    const speedWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(speedExcel)

    const excelData: ExcelData = {
        worksheet: speedWorksheet,
        sheetName: 'Speed Report',
    }

    return [excelData]
}
